<template>
    <div :class="['login-form', layout ? `login-form--${layout}` : '']">
        <template v-if="loading">
            <Loading></Loading>
        </template>
        <template v-else-if="!success">
            <div class="alert alert-danger" v-if="error">{{ error }}</div>

            <form action="#" @submit.prevent="onFormSubmit()">
                <TextField v-model="username" placeholder="E-mail" :validator="$v.username" :validator-instant="false" required></TextField>

                <TextField v-model="password" type="password" placeholder="Password" :validator="$v.password" :validator-instant="false" required></TextField>

                <div class="login-form__row">
                    <div class="login-form__remember-me">
                        <Checkbox v-model="autologin" label="Remember me"></Checkbox>
                    </div>
                    <div class="login-form__forgot-password">
                        <a :href="lostPasswordUrl" title="Forgot password" class="link-underline font-weight-bold">Forgot password?</a>
                    </div>
                </div>

                <div class="login-form__submit">
                    <button type="submit" class="btn btn-primary" v-if="layout === 'checkout'">Sign In</button>
                    <button type="submit" class="btn btn-primary btn-icon" v-else><span>Sign In</span><i class="icon icon-chevron-right"></i></button>
                </div>
            </form>
        </template>
        <template v-else>
            <Loading iconClass="icon-user-check">
                <template v-slot:title>
                    You have successfully logged in!
                </template>
                <template v-slot:text>
                    Please wait while the page refreshes …
                </template>
            </Loading>
        </template>
    </div>
</template>

<script>
    import parse from 'url-parse';
    import qs from 'querystring';
    import { required, email } from 'vuelidate/lib/validators';
    import {handleAjaxError} from '../_helpers/error-handler';
    import Loading from "./Loading";
    import Checkbox from './Checkbox';
    import TextField from './TextField';

    export default {
        name: 'LoginForm',
        components: {
            Loading,
            Checkbox,
            TextField,
        },
        props: {
            lostPasswordUrl: { type: String, required: true},
            layout: {type: String, default: ''},
        },
        data() {
            return {
                error: false,
                success: false,
                loading: false,
                username: null,
                password: null,
                autologin: false,
            };
        },
        watch: {
            username() {
                this.error = false;
            },
            password() {
                this.error = false;
            },
        },
        validations: {
            username: { required, email },
            password: { required },
        },
        methods: {
            onFormSubmit() {
                this.$v.$touch();

                if (this.$v.$invalid) {
                    return;
                }

                const url = parse(window.location.href, true);

                const data = {
                    username: this.username,
                    password: this.password,
                    autologin: this.autologin ? 1 : 0,
                    FORM_SUBMIT: 'tl_login',
                };

                // Add the target path from the current query referer
                if (url.query && url.query.redirect) {
                    data['_target_path'] = btoa(url.query.redirect);
                } else {
                    data['_target_path'] = btoa(window.location.origin + window.location.pathname);
                }

                this.loading = true;

                this.$http.post(window.location.href, qs.stringify(data), {
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    maxRedirects: 0,
                }).then(response => {
                    this.success = true;
                    window.location.href = response.data;
                }).catch(error => {
                    if (error.response.status === 401) {
                        this.error = error.response.data;
                    } else {
                        handleAjaxError(error);
                    }
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
    }
</script>
