var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-control custom-checkbox" },
    [
      _c("input", {
        staticClass: "custom-control-input",
        attrs: {
          type: "checkbox",
          id: _vm.id,
          required: _vm.required,
          disabled: _vm.disabled
        },
        domProps: { checked: !!_vm.value },
        on: {
          change: function($event) {
            _vm.$emit("input", $event.target.checked) &&
              _vm.validator &&
              _vm.validator.$touch()
          }
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "custom-control-label", attrs: { for: _vm.id } },
        [
          _vm._t("default", [_vm._v(_vm._s(_vm.label))]),
          _vm._v(" "),
          _vm.required
            ? _c("span", { staticClass: "required" }, [_vm._v("*")])
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("FieldError", {
        attrs: { validator: _vm.validator, message: _vm.error }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }