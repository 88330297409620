var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LoginForm", {
    attrs: {
      "lost-password-url": this.$config.lostPasswordUrl,
      layout: this.$config.layout
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }