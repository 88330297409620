var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["login-form", _vm.layout ? "login-form--" + _vm.layout : ""] },
    [
      _vm.loading
        ? [_c("Loading")]
        : !_vm.success
        ? [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "form",
              {
                attrs: { action: "#" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.onFormSubmit()
                  }
                }
              },
              [
                _c("TextField", {
                  attrs: {
                    placeholder: "E-mail",
                    validator: _vm.$v.username,
                    "validator-instant": false,
                    required: ""
                  },
                  model: {
                    value: _vm.username,
                    callback: function($$v) {
                      _vm.username = $$v
                    },
                    expression: "username"
                  }
                }),
                _vm._v(" "),
                _c("TextField", {
                  attrs: {
                    type: "password",
                    placeholder: "Password",
                    validator: _vm.$v.password,
                    "validator-instant": false,
                    required: ""
                  },
                  model: {
                    value: _vm.password,
                    callback: function($$v) {
                      _vm.password = $$v
                    },
                    expression: "password"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "login-form__row" }, [
                  _c(
                    "div",
                    { staticClass: "login-form__remember-me" },
                    [
                      _c("Checkbox", {
                        attrs: { label: "Remember me" },
                        model: {
                          value: _vm.autologin,
                          callback: function($$v) {
                            _vm.autologin = $$v
                          },
                          expression: "autologin"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "login-form__forgot-password" }, [
                    _c(
                      "a",
                      {
                        staticClass: "link-underline font-weight-bold",
                        attrs: {
                          href: _vm.lostPasswordUrl,
                          title: "Forgot password"
                        }
                      },
                      [_vm._v("Forgot password?")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "login-form__submit" }, [
                  _vm.layout === "checkout"
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("Sign In")]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-icon",
                          attrs: { type: "submit" }
                        },
                        [
                          _c("span", [_vm._v("Sign In")]),
                          _c("i", { staticClass: "icon icon-chevron-right" })
                        ]
                      )
                ])
              ],
              1
            )
          ]
        : [
            _c("Loading", {
              attrs: { iconClass: "icon-user-check" },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n                You have successfully logged in!\n            "
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "text",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n                Please wait while the page refreshes …\n            "
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }