<template>
    <div class="custom-control custom-checkbox">
        <input class="custom-control-input"
               type="checkbox"
               :id="id"
               :required="required"
               :disabled="disabled"
               :checked="!!value"
               @change="$emit('input', $event.target.checked) && validator && validator.$touch()"
        >
        <label class="custom-control-label" :for="id">
            <slot>{{ label }}</slot>
            <span v-if="required" class="required">*</span>
        </label>
        <FieldError :validator="validator" :message="error"></FieldError>
    </div>
</template>

<script>
    import slugify from 'slugify';
    import FieldError from './FieldError';

    export default {
        name: 'Checkbox',
        components: {
            FieldError,
        },
        props: {
            error: { type: String, default: '' },
            disabled: { type: Boolean, default: false },
            label: { type: String, default: '' },
            required: { type: Boolean, default: false },
            validator: { type: Object, default: null },
            value: { type: Boolean, default: false },
        },
        computed: {
            id() {
                let id = 'lbl-';

                if (this.label) {
                    id = `${id}${slugify(this.label, { lower: true })}-`;
                }

                return `${id}${this._uid}`
            }
        }
    }
</script>
