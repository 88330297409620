<template>
    <div class="invalid-feedback" v-if="show">{{ errorMessage }}</div>
</template>

<script>
    const messages = {
        alphaNum: 'Only alphanumeric characters are allowed!',
        email: 'Please enter a valid e-mail address!',
        fileLimit: 'Maximum of {limit} files is allowed!',
        fileSize: 'File size should be smaller than {limit} megabytes!',
        minLength: 'Please enter at least {min} characters!',
        maxLength: 'The maximum number of characters is {max}!',
        required: 'This field is required!',
        sameAs: 'The value must be the same as {eq}!',
        serverValidator: '{message}',
    };

    export default {
        name: 'FieldError',
        props: {
            message: { type: String, default: null },
            validator: { type: Object, default: null },
        },
        computed: {
            errorMessage() {
                if (this.message) {
                    return this.message;
                }

                // Try to find the default message
                for (const [type, params] of Object.entries(this.validator.$params)) {
                    if (!this.validator[type] && messages.hasOwnProperty(type)) {
                        return messages[type].replace(/{([^{}]+)}/g, (fullMatch, match) => {
                            return params[match];
                        });
                    }
                }

                return null;
            },
            show() {
                if (this.message) {
                    return this.message;
                }

                return this.validator && this.validator.$error && this.errorMessage;
            }
        }
    }
</script>
