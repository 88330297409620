<template>
    <LoginForm :lost-password-url="this.$config.lostPasswordUrl" :layout="this.$config.layout"></LoginForm>
</template>

<script>
    import LoginForm from '../_components/LoginForm';

    export default {
        name: 'Login',
        components: {
            LoginForm,
        }
    }
</script>
