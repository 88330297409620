<template>
    <div class="form-group" :class="{'form-group--switcher': typeSwitch}">
        <div :class="{ 'form-label-group': labelGroup }">
        <input :type="inputType"
               :id="id"
               :class="cssClass"
               :placeholder="placeholder || labelGroup"
               :value="value"
               :required="required"
               :disabled="disabled"
               @input="$emit('input', $event.target.value)"
               @change="validator && validatorInstant && validator.$touch()"
               @blur="validator && validatorInstant && validator.$touch()"
        >
        <button class="input-type-switcher" type="button" v-if="typeSwitch" @click.prevent="onInputTypeSwitch">
            <i class="icon-eye" v-if="inputType === type"></i>
            <i class="icon-eye-off" v-if="inputType === typeSwitch"></i>
        </button>
            <label :for="id" v-if="labelGroup"><span v-html="labelGroup"></span><span v-if="required" class="required">*</span></label>
        </div>
        <slot name="feedback">
            <FieldError :validator="validator" :message="error"></FieldError>
        </slot>
        <small class="form-hint" v-if="hint">{{ hint }}</small>
    </div>
</template>

<script>
    import slugify from 'slugify';
    import FieldError from './FieldError';

    export default {
        name: 'TextField',
        components: {
            FieldError,
        },
        data() {
            return {
                inputType: this.type,
            };
        },
        props: {
            error: { type: String, default: '' },
            disabled: { type: Boolean, default: false },
            hint: { type: String, default: '' },
            label: { type: String, default: '' },
            placeholder: { type: String, default: '' },
            required: { type: Boolean, default: false },
            type: { type: String, default: 'text' },
            typeSwitch: { type: String, default: null },
            validator: { type: Object, default: null },
            validatorInstant: { type: Boolean, default: true },
            value: { type: [String, Number], default: null },
        },
        computed: {
            cssClass() {
                const classes = ['form-control'];

                if (this.validator) {
                    if (this.validator.$error) {
                        classes.push('is-invalid');
                    } else if (this.value && this.validatorInstant) {
                        classes.push('is-valid');
                    }
                }

                return classes.join(' ');
            },
            id() {
                let id = 'lbl-';

                if (this.label) {
                    id = `${id}${slugify(this.label, { lower: true })}-`;
                }

                return `${id}${this._uid}`
            },
            labelGroup() {
                return this.label ? this.label : this.placeholder;
            }
        },
        methods: {
            onInputTypeSwitch() {
                this.inputType = (this.inputType === this.typeSwitch) ? this.type : this.typeSwitch;
            },
        },
    }
</script>
