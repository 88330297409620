var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group",
      class: { "form-group--switcher": _vm.typeSwitch }
    },
    [
      _c("div", { class: { "form-label-group": _vm.labelGroup } }, [
        _c("input", {
          class: _vm.cssClass,
          attrs: {
            type: _vm.inputType,
            id: _vm.id,
            placeholder: _vm.placeholder || _vm.labelGroup,
            required: _vm.required,
            disabled: _vm.disabled
          },
          domProps: { value: _vm.value },
          on: {
            input: function($event) {
              return _vm.$emit("input", $event.target.value)
            },
            change: function($event) {
              _vm.validator && _vm.validatorInstant && _vm.validator.$touch()
            },
            blur: function($event) {
              _vm.validator && _vm.validatorInstant && _vm.validator.$touch()
            }
          }
        }),
        _vm._v(" "),
        _vm.typeSwitch
          ? _c(
              "button",
              {
                staticClass: "input-type-switcher",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onInputTypeSwitch($event)
                  }
                }
              },
              [
                _vm.inputType === _vm.type
                  ? _c("i", { staticClass: "icon-eye" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.inputType === _vm.typeSwitch
                  ? _c("i", { staticClass: "icon-eye-off" })
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.labelGroup
          ? _c("label", { attrs: { for: _vm.id } }, [
              _c("span", { domProps: { innerHTML: _vm._s(_vm.labelGroup) } }),
              _vm.required
                ? _c("span", { staticClass: "required" }, [_vm._v("*")])
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("feedback", [
        _c("FieldError", {
          attrs: { validator: _vm.validator, message: _vm.error }
        })
      ]),
      _vm._v(" "),
      _vm.hint
        ? _c("small", { staticClass: "form-hint" }, [_vm._v(_vm._s(_vm.hint))])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }